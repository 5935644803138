import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/business/billPush/pageList", formData);
}

function payServiceList(formData){
  return request.post(constant.serverUrl + "/business/billPush/payServiceList", formData);
}

function goodsPageList(formData){
  return request.post(constant.serverUrl + "/business/billPush/goodsPageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/business/billPush/create");
}

function createGoods(){
  return request.get(constant.serverUrl + "/business/billPush/createGoods");
}

function edit(id){
  return request.get(constant.serverUrl + "/business/billPush/edit/" + id);
}

function detail(id){
  return request.get(constant.serverUrl + "/business/billPush/detail/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/business/billPush/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/business/billPush/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/business/billPush/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/business/billPush/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function pushBill(formData){
  return request.post(constant.serverUrl + "/business/billPush/pushBill", formData);
}

function closeBill(id){
  return request.post(constant.serverUrl + "/business/billPush/closeBill/" + id);
}

function updateOrder(formData){
  return request.post(constant.serverUrl + "/business/billPush/updateOrder", formData,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function createPayTime(id){
  return request.get(constant.serverUrl + "/business/billPush/createPayTime"+ id);
}


function editPayTime(id){
  return request.get(constant.serverUrl + "/business/billPush/editPayTime/" + id);
}

function updateIsOpenWechatOrAlipay(formData){
  return request.post(constant.serverUrl + "/business/billPush/updateIsOpenWechatOrAlipay/",formData);
}

function checkInBillpageList(formData){
  return request.post(constant.serverUrl + "/business/billPush/checkInBillpageList", formData);
}

function wxQucode(orderId){
  return request.get(constant.serverUrl + "/wxPay/wxNativePay?orderId="+orderId);
}

function alipayCode(orderId){
  return request.get(constant.serverUrl + "/aliPay/tradePrecreatePay?orderId="+orderId);
}

function invoiceTypeList(){
  return request.post(constant.serverUrl + "/business/billPush/invoiceTypeList");
}

function billPushGoodStatisticsList(formData){
  return request.post(constant.serverUrl + "/business/billPush/billPushGoodStatisticsList", formData);
}

function getInvoiceTypeList(){
  return request.post(constant.serverUrl + "/business/billPush/getInvoiceTypeList");
}





export default {
    pageList,create,edit,add,update,remove,batchRemove,payServiceList,createGoods,detail,goodsPageList,pushBill,closeBill,updateOrder,createPayTime,editPayTime
    ,updateIsOpenWechatOrAlipay,checkInBillpageList,wxQucode,alipayCode,invoiceTypeList,billPushGoodStatisticsList,getInvoiceTypeList
  }