import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/pageList", formData);
}

function list() {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/list");
}


function create() {
  return request.get(constant.serverUrl + "/base/companyPaymentInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/companyPaymentInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function updateIsOpenWechatOrAlipay(formModel) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/updateIsOpenWechatOrAlipay", formModel);
}

function loadDetails(formData) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/loadDetails", formData);
}

function getCompanyList() {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/getCompanyList");
}

function findByCheckInId(formData) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/findByCheckInId",formData);
}

function setIsDefault(formData) {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/setIsDefault",formData);
}

function findUserByCompanyId() {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/findUserByCompanyId");
}


function findPersonByCompanyId() {
  return request.post(constant.serverUrl + "/base/companyPaymentInfo/findPersonByCompanyId");
}




export default {
    create, edit, add, update, remove, batchRemove, pageList,list,updateIsOpenWechatOrAlipay,loadDetails,getCompanyList,findByCheckInId,setIsDefault
    ,findPersonByCompanyId,findUserByCompanyId
}