<template>
    <el-dialog
    title="付款码"
    :visible.sync="showDialog"
    :modal-append-to-body="true"
    style="text-align: center;"
    width="43%"
    @close="closeDialog"
    append-to-body
    :close-on-click-modal="false"
    >

    <el-row :gutter="20">
        <el-col :span="12" style="text-align:center;">
            <el-image class="qrcode"  
            src="http://rccs.oss-cn-hangzhou.aliyuncs.com/jp_housekeeper/img/%E5%BE%AE%E4%BF%A1%E6%94%AF%E4%BB%98.png"
            fit="contain"
            ></el-image>
            <div class="grid-content bg-purple" id="wxQuCode" ref="wxQuCode"></div>
        </el-col>
        <el-col :span="12" style="text-align:center;">
            <el-image  class="qrcode" 
            src="http://rccs.oss-cn-hangzhou.aliyuncs.com/jp_housekeeper/img/%E6%94%AF%E4%BB%98%E5%AE%9D%E6%94%AF%E4%BB%98.png"
            fit="contain"
            ></el-image>
            <div class="grid-content bg-purple" id="alipayQuCode" ref="alipayQuCode"></div>
        </el-col>
    </el-row>
    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import billPushApi from "@/api/business/billPush";
import QRCode from 'qrcodejs2';
export default {
    props: ["businessKey", "title","orderId"],
    data(){
        return{
            loading:false,
            showDialog:true,
            submitting: false,
        }
    },
    methods:{
        closeDialog() {
            this.$emit("close", false);
        },
    },
    mounted: function() {
        var self = this;

        billPushApi.wxQucode(self.orderId).then((response)=>{
            var jsonData = response.data;

            if(jsonData.result){
                var qucode = jsonData.data;

                new QRCode(this.$refs.wxQuCode, {
                    text: qucode,
                    width: 200,
                    height: 200,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                })
            }
            else{
                self.$message.error(jsonData.message + "");
            }
        });

        billPushApi.alipayCode(self.orderId).then((response)=>{
            var jsonData = response.data;

            if(jsonData.result){
                var qucode = jsonData.data;
                new QRCode(this.$refs.alipayQuCode, {
                    text: qucode,
                    width: 200,
                    height: 200,
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                })
            }
            else{
                self.$message.error(jsonData.message + "");
            }
        });
    }
}
</script>
<style scoped>
  .el-row {
    margin-bottom: 20px;
  }
  .el-col {
    border-radius: 4px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    width: 200px;
    height:200px;
    margin:5px auto;
  }
  .row-bg {
    padding: 10px 0;
  }

  .qrcode{
      width: 160px;
      height: 120px;
  }
</style>