<template>
    <div>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">系统管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">账单推送管理</a>
            </el-breadcrumb-item>
            </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="账单名称" prop="name">
                <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
            </el-form-item>
            <el-form-item label="推送状态" prop="status">
                <el-select v-model="queryModel.status"  size="mini"  >
                    <el-option
                        v-for="status in statusList"
                        :key="status.id"
                        :label="status.name"
                        :value="status.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="缴费状态" prop="payStatus">
                <el-select v-model="queryModel.payStatus"  size="mini"  >
                    <el-option
                        v-for="payStatus in payStatusList"
                        :key="payStatus.id"
                        :label="payStatus.name"
                        :value="payStatus.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="缴费方式" prop="payName">
                <el-select v-model="queryModel.payName"  size="mini"  >
                    <el-option
                        v-for="payName in payNameList"
                        :key="payName.id"
                        :label="payName.name"
                        :value="payName.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="收款账户" prop="collectionAccount">
                <el-select v-model="queryModel.collectionAccount"  size="mini"  >
                    <el-option
                        v-for="collectionAccount in collectionAccountList"
                        :key="collectionAccount.id"
                        :label="collectionAccount.name"
                        :value="collectionAccount.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="推送时间" prop="pushTimeRange">
                <el-date-picker
                    v-model="queryModel.pushTimeRange"
                    type="daterange"
                    size="mini"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="支付时间" prop="payTimeRange">
                <el-date-picker
                    v-model="queryModel.payTimeRange"
                    type="daterange"
                    size="mini"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="收费项目：" prop="payService">
            <el-select v-model="queryModel.payService"  size="mini">
                <el-option
                    v-for="payService in payServiceList"
                    :key="payService.id"
                    :label="payService.goodsName"
                    :value="payService.id"
                ></el-option>
            </el-select>
            </el-form-item> -->
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
                >重置</el-button>&nbsp;
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- <el-row class="button-group">
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-circle-plus"
                @click="handleAdd"
                >创建账单</el-button
            >
        </el-row> -->
        <el-table 
        ref="formTable"
        :data="tableData" 
        :height="tableHeight"
        v-loading="loading" stripe>
            <el-table-column type="index" label="序号" :index="indexMethod" width="50"></el-table-column>
            <el-table-column prop="name" label="账单名称" width="180"></el-table-column>
            <el-table-column prop="companyName" label="缴费单位" width="180"></el-table-column>
            <el-table-column prop="personName" label="推送用户" width="150">
                <template slot-scope="{row}">
                    <div>{{row.personName}}</div>
                    <div>{{row.phone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionAccount" label="收款账户" ></el-table-column>
            <el-table-column prop="totalFee" label="订单总额" ></el-table-column>
            <el-table-column prop="status" label="账单状态">
                <template slot-scope="{row}">
                    <span v-if="row.status=='1'" style="color:#67C23A">已推送</span>
                    <span v-else-if="row.status=='2'" style="color:#E6A23C">推送失败</span>
                    <span v-else-if="row.status=='3'" style="color:#909399">已关闭</span>
                    <span v-else style="color:#F56C6C">未推送</span>
                </template>
            </el-table-column>
            <el-table-column prop="pushTime" label="推送时间"  width="180"></el-table-column>
            <el-table-column prop="payStatus" label="缴费状态" >
                <template slot-scope="{row}">
                    <span v-if="row.payStatus=='10'" style="color:#F56C6C">未支付</span>
                    <span v-else-if="row.payStatus=='15'" style="color:#E6A23C">部分支付</span>
                    <span v-else-if="row.payStatus=='20'" style="color:#67C23A">已支付</span>
                    <span v-else-if="row.payStatus=='30'" style="color:#909399">已关闭</span>
                    <span v-else-if="row.payStatus=='40'" style="color:#909399">已退款</span>
                </template>
            </el-table-column>
            <el-table-column prop="payTime" label="缴费时间" width="180"></el-table-column>
            <el-table-column prop="payName" label="支付方式" ></el-table-column>
            <el-table-column prop="payee" label="收款方" ></el-table-column>
            <!-- <el-table-column prop="isOpenWechat"  label="支持微信支付"  >
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isOpenWechat"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="handlChange(row,'0')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="isOpenAlipay"  label="支持支付宝支付"  >
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isOpenAlipay"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="handlChange(row,'1')">
                    </el-switch>
                </template>
            </el-table-column> -->
            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="{row}">
                   <el-link  type="primary" @click="handleDetailList(row)">查看详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
        <billPush-checkIn-detail
            v-if="showModal2"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle2"
            @close="onDetailModalClose2"
        ></billPush-checkIn-detail>
    </div>
</template>
<script>
import Constant from "@/constant";
import billPushApi from "@/api/business/billPush";
import companyInfoApi from "@/api/base/companyInfo";
import companyPaymentApi from "@/api/base/companyPayment";
import billPushCheckInDetail from "./billPush-checkIn-detail";
import pageUtil from "@/utils/page";

export default {
    name: "businessbillPushCheckInList",
    data() {
        var self = this;

        return {
            queryModel: {
                name:"",
                companyId:"",
                status: "",
                payName:"",
                collectionAccount:"",
                pushTimeRange: "",
                payTimeRange:"",
                payStatus:"",
            },
            treeData: [],
            loading: false,
            statusList:[
                {
                    id:"0",
                    name:"未推送",
                },
                {
                    id:"1",
                    name:"已推送",
                },
                {
                    id:"2",
                    name:"推送失败",
                },
                {      
                    id:"3",
                    name:"已关闭",
                }
            ],
            tableData: [],
            payStatusList:[
                {
                    id:"10",
                    name:"未支付",
                },
                {
                    id:"15",
                    name:"部分支付",
                },
                {
                    id:"20",
                    name:"已支付",
                },
                {      
                    id:"30",
                    name:"已关闭",
                },
                {      
                    id:"40",
                    name:"已退款",
                }
            ],
            payNameList:[
                {
                    id:"wechat",
                    name:"微信",
                },
                {
                    id:"alipay",
                    name:"支付宝",
                },
                {
                    id:"cash",
                    name:"现金",
                },
            ],
            collectionAccountList:[],
            companyList:[],
            pageIndex: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0,
            pageSizeList: [10, 20, 30],
            multipleSelection: [],
            showModal: false,
            showModal2: false,
            showModal3:false,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
            tableHeight: 400
        }
    },
    created() {

        companyPaymentApi.list().then((response)=>{
            var jsonData = response.data;
            this.collectionAccountList = jsonData.data;
        });

        setTimeout(()=>{
            this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
        },1000);
    },
    methods: {
        indexMethod(index) {
            return (this.pageIndex - 1) * this.pageSize + (index + 1);
        },
        changePage(pageIndex) {
            var self = this;

            self.loading = true;

            self.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("pageIndex", self.pageIndex);
            formData.append("pageSize", self.pageSize);

            formData.append("name", self.queryModel.name);
            formData.append("status", self.queryModel.status);
            formData.append("payName", self.queryModel.payName);

            formData.append("collectionAccount", self.queryModel.collectionAccount);
            formData.append("pushTimeRange", self.queryModel.pushTimeRange);
            formData.append("payTimeRange", self.queryModel.payTimeRange);
            formData.append("payStatus", self.queryModel.payStatus);


            billPushApi.checkInBillpageList(formData)
                .then(function(response) {
                    self.loading = false;

                    var jsonData = response.data.data;

                    self.tableData = jsonData.data;
                    self.totalPages = jsonData.totalPages;
                    self.totalElements = jsonData.recordsTotal;
                })
                .catch(error => {
                    self.loading = false;
                    // self.$message.error(error + "");
                });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleAdd(record) {
            this.modalTitle = "账单信息";
            this.operation = "add";
            this.businessKey = "";

            if(record!=null){
                this.selectedRecord = record;
            }
            else{
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        loadTree() {
            companyInfoApi.treeList().then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                this.treeData = jsonData.data;
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },
        onDetailModalClose2(retObj) {
            //保存成功后回调
            this.showModal2 = false;

            if(retObj){
                this.changePage(this.pageIndex);
            }            
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handChange(selVal){

            this.queryModel.payService = "";

            var formData = new FormData();
            formData.append("companyId", selVal);

            billPushApi.payServiceList(formData).then((response)=>{
                var jsonData = response.data;
                this.payServiceList = jsonData.data;
            });
        },
        handleDetailList(record){
            this.modalTitle2 = "账单推送详情";
            this.operation = "detail";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal2 = true;
        },
        handlePush(record){
            var id = record.id;

            this.$confirm("是否确认推送账单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            })
            .then(() => {
            
                var formData = new FormData();
                formData.append("id", id);

                billPushApi.pushBill(formData).then((response)=>{
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.changePage(this.pageIndex);
                        this.$message.success("账单消息推送成功！");
                    } else {
                        this.$message.error(jsonData.message + "");
                    }

                });
            });
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                billPushApi.remove(record.id).then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    var rs = self.loopDelete(self.tableData,record.id);

                    self.$message({
                        type: "success",
                        message: "删除成功!"
                    });
                    }
                });
            });
        },
        handleClose(record) {
            var self = this;

            self.$confirm("是否确认关闭?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                billPushApi.closeBill(record.id).then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                        self.changePage(self.pageIndex);
                        this.$message.success("关闭成功！");
                    } else {
                        this.$message.error(jsonData.message + "");
                    }

                });
            });
        },
        loopDelete(list,id){
            var rs = false;

            for(var i=0;i<list.length;i++){
                if(list[i].id == id){
                list.splice(i,1);
                rs = true;
                break;
                }

                if(list[i].children!=null){
                rs = this.loopDelete(list[i].children,id);

                if(rs){
                    break;
                }
                }
            }

            return rs;
        }, 
        handlePayTime(record){
            var self = this;

            this.modalTitle3 = "账单推送详情";
            this.operation = "detail";
            this.businessKey = record.id;
            this.showModal3 = true;
        },
        handlChange(record,type){
            var self = this;

            var formData = new FormData();
            self.loading = true;

            formData.append("id", record.id);
            if(type=="0"){
                formData.append("isOpen", record.isOpenWechat);
            }
            else{
                formData.append("isOpen", record.isOpenAlipay);
            }
            formData.append("type", type);

            billPushApi.updateIsOpenWechatOrAlipay(formData).then(function (response) {
                var jsonData = response.data;
                self.loading = false;
                if (jsonData.result) {
                    self.changePage(self.pageIndex);
                }
                else{
                    self.$message.error(jsonData.message + "");
                }
            });

        }
    },
    mounted: function() {
        this.changePage(1);
    },
    components: {
        "billPush-checkIn-detail":billPushCheckInDetail
    }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
.link-span{
    margin-right: 30px;
}
.span-view{
    margin-left: 10px;
}
</style>